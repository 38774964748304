var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("voucher-code-editor", {
    attrs: {
      applicationId: _vm.applicationId,
      voucherRuleId: _vm.voucherRuleId,
      voucherCodeId: _vm.voucherCodeId,
      readOnly: true,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }